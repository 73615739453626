import React from "react";
import {Box, Typography} from "@mui/material";

export const Regolamento = ({t}) => {
    const rules = [
        t('stepTwo.rules.1'),
        t('stepTwo.rules.2'),
        t('stepTwo.rules.3'),
        t('stepTwo.rules.4'),
        t('stepTwo.rules.5'),
        t('stepTwo.rules.6'),
    ]
  return(
      <Box sx={{width: '100%'}}>
          <Typography variant={'h4'} textTransform={'uppercase'} textAlign={'center'} fontWeight={'bold'}>
              {t('stepTwo.rules.title')}
          </Typography>
          <Box sx={{pb:8, pt:2}}>
                {
                    rules.map((rule, index) => (
                        <Typography key={index} variant={'body1'} sx={{py: 1}}>
                            {rule}
                        </Typography>
                    ))
                }
          </Box>
      </Box>
  )
}