import React from "react";
import {Box} from "@mui/material";

export const Footer = () => {
  return(
      <Box>
          <Box sx={{width:'min(7rem, 100%)', margin:'auto'}}>
              <img src={require('../assets/images/logo-footer.png')} style={{width:'100%'}}/>
          </Box>
      </Box>
  )
}