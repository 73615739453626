import React, {useState} from "react";
import {Box} from "@mui/material";
import {StepOne} from "./StepOne";
import {StepTwo} from "./StepTwo";

export const HomeContent = () => {
    const [step, setStep] = useState(0)
    const [email, setEmail] = useState('')

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    return (
        <Box sx={{pb: 10}}>
            {
                step === 0 ?
                    <StepOne handleChangeStep={setStep} email={email} handleChangeEmail={handleChangeEmail}/>
                    :
                    <StepTwo email={email}/>
            }
        </Box>
    )
}