import React from "react";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {FileUploader} from "react-drag-drop-files";
import {Upload} from "@mui/icons-material";
import {Trans} from "react-i18next";
import {API_URL} from "../../config";
import axios from "axios";
import {CustomAlert} from "../CustomAlert";

export const PhotoForm = ({setSuccess, setMessage, t, email}) => {
    const [image, setImage] = React.useState(null);
    const [url, setUrl] = React.useState("");
    const [sending, setSending] = React.useState(false);
    const [checked, setChecked] = React.useState(true);
    const [error, setError] = React.useState(false);

    const handleChangeChecked = (event) => {
        setChecked(event.target.checked);
    };

    const handleChangeImage = (file) => {
        setImage(file);
        setUrl(URL.createObjectURL(file))
    };

    const sendData = async (e) => {
        e.preventDefault()
        setSending(true)
        const formData = new FormData()
        formData.append('file', image)
        formData.append('email', email)
        await axios.post(`${API_URL}/register`, formData)
            .then(res => setSuccess(true))
            .catch(err => {
                console.log(err.response.status)
                setError(true)
            })
        setSending(false)
    }

    return (
        <Container maxWidth={'xs'}>
            <CustomAlert open={error} setOpen={setError} severity={'error'} message={t('stepTwo.form.error')}/>
            <form style={{width: '100%'}} onSubmit={sendData}>
                <Stack sx={{width: '100%'}} spacing={2}>
                    <FileUploader
                        multiple={false}
                        handleChange={handleChangeImage}
                        name="file"
                        types={["jpg", "jpeg", "png"]}
                        children={
                            <Stack alignItems={'center'} justifyContent={'center'} sx={{
                                width: '100%',
                                aspectRatio: '1/1',
                                p: 2,
                                border: '2px solid white',
                                borderRadius: '1rem',
                                textAlign: 'center'
                            }}>
                                <Box>
                                    {url !== '' && <img src={url} style={{width: '100%'}}/>}
                                    <Typography variant={"h5"} sx={{mb: 1}} fontWeight={'bold'}>
                                        {t('stepTwo.form.clickOrDrag')} {image ? t('stepTwo.form.change') : t('stepTwo.form.upload')}
                                    </Typography>
                                    <Upload sx={{fontSize: '3rem'}}/>
                                </Box>
                            </Stack>
                        }
                    />
                    {sending ?
                        <Box sx={{textAlign: 'center'}}>
                            <CircularProgress/>
                        </Box>
                        :
                        <Button variant={'contained'} type={'submit'} disabled={!image}>
                            {t('stepTwo.form.send')}
                        </Button>
                    }

                </Stack>
            </form>
        </Container>
    )
}