import './App.css';
import {Box, Container, CssBaseline} from "@mui/material";
import {HomeContent} from "./components/HomeContent";
import {Footer} from "./components/Footer";
import LangSwitcher from "./components/LangSwitcher";
/**/
function App() {
    return (
        <main>
            <CssBaseline/>
            <Container maxWidth={'sm'}>
                <Box sx={{textAlign:'right',pt:2}}>
                    <LangSwitcher/>
                </Box>
                <Box sx={{pb: 6, pt:4}}>
                    <img src={require('./assets/images/logo-evento.png')} style={{width: '100%'}} alt={'logo-evento'}/>
                </Box>
                <HomeContent/>
                <Footer/>
            </Container>
        </main>
    );
}

export default App;
