import React from "react";
import {Box, Typography} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {Regolamento} from "./StepTwo/Regolamento";
import {PhotoForm} from "./StepTwo/PhotoForm";
import {CustomAlert} from "./CustomAlert";

export const StepTwo = ({email}) => {
    const {t} = useTranslation()
    const [success, setSuccess] = React.useState(false);
    const [message, setMessage] = React.useState('');

    return (
        <Box>
            {
                success ?
                    <Box>
                        <Typography textTransform={'uppercase'} variant={'h4'} textAlign={'center'} fontWeight={'bold'}>
                            <Trans i18nKey={'stepTwo.thanks'}/>
                        </Typography>
                        <Typography textTransform={'uppercase'} variant={'h5'} textAlign={'center'} sx={{pt:2}}>
                            <Trans i18nKey={'stepTwo.thanks1'}/>
                            <br/>
                            <br/>
                            <Trans i18nKey={'stepTwo.thanks2'}/>
                        </Typography>
                    </Box>
                    :
                    <Box>
                        <Typography variant={'h4'} textAlign={'center'} textTransform={'uppercase'} sx={{pb:9}}>
                            <b>BE TOGETHER</b>
                            <br/>
                            {t('stepTwo.intro1')}
                            <br/>
                            {t('stepTwo.intro2')}
                            <br/>
                            {t('stepTwo.intro3')}
                            <br/>
                            <br/>
                            {t('stepTwo.intro4')}
                        </Typography>
                        <Regolamento t={t}/>
                        <PhotoForm t={t} setSuccess={setSuccess}
                                   email={email}
                                   setMessage={setMessage}/>
                    </Box>
            }
        </Box>
    )
}