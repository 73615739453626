import {createTheme, responsiveFontSizes} from "@mui/material/styles";

export const monclerTheme = responsiveFontSizes(createTheme({
    typography: {
        fontFamily: [
            'Moncler Gothic',
            'sans-serif',
        ].join(',')},
    components:{
        MuiButton:{
            styleOverrides:{
                root:{
                    borderRadius:'20rem',
                    height:'3rem',
                    fontSize:'1.3rem',
                    fontWeight:'600',
                }
            }
        }
    },
    palette: {
        mode: 'dark',
        text:{
            primary:"#F1F1F1"
        },
        primary: {
            main: '#CBCBCB',
            contrastText:"#1A1A1A"
        },
        background: {
            default: '#1A1A1A',
        },
    }
}));