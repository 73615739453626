import React from "react";
import {Box, Typography} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";

export default function IscrivitiTextBlock() {
    const {i18n, t} = useTranslation()

    return (
        <Box>
            <Typography textAlign={'center'} variant={'h3'} fontWeight={'bold'} textTransform={'uppercase'}>
                {t('stepOne.intro')}
            </Typography>
            <Typography textAlign={'center'} variant={'h5'} sx={{py:4}} textTransform={'uppercase'}>
                <Trans i18nKey={'stepOne.intro4'}/>
                <br/>
                <Trans i18nKey={'stepOne.intro5'}/>
                <br/>
                <Trans i18nKey={'stepOne.intro6'}/>
                <br/>
                <Trans i18nKey={'stepOne.intro7'}/>
                <br/>
                <br/>
                <Trans i18nKey={'stepOne.intro8'}/>
            </Typography>
            </Box>
    )
}