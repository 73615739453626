import React, {useEffect, useState} from "react";
import i18n from "../services/i18n";
import {MenuItem, Select} from "@mui/material";

const LangSwitcher = (color) => {
    const [language, setLanguage] = useState(i18n.language ? i18n.language : 'en')

    useEffect(() => {
        let l = ['it','en']
        //l.includes(navigator.language.split('-')[0]) ? setLanguage(navigator.language.split('-')[0]) : setLanguage('en')
        i18n.changeLanguage(language)
    }, []);

    const changeLanguage = (e) => {
        setLanguage(e.target.value)
        i18n.changeLanguage(e.target.value)
    }

    return(
        <Select variant={'standard'} sx={{textAlign:'left', color: color ? color : 'black' }} value={language} onChange={changeLanguage}>
            <MenuItem value={'it'}>IT</MenuItem>
            <MenuItem value={'en'}>EN</MenuItem>
        </Select>
    )
}

export default LangSwitcher
