import React from "react";
import {Box, Button, Checkbox, FormControlLabel, Stack, TextField, Typography} from "@mui/material";
import IscrivitiTextBlock from "./StepOne/IscrivitiTextBlock";
import {Trans, useTranslation} from "react-i18next";

export const StepOne = ({handleChangeStep, handleChangeEmail, email}) => {
    const {t} = useTranslation()

    const handleSubmit = (e) => {
        e.preventDefault()
        handleChangeStep(1)
    }

    return (
        <Box sx={{width: '100%'}}>
            <IscrivitiTextBlock/>
            <Stack sx={{px: {xs: 0, md: 2}}} component={'form'} onSubmit={handleSubmit} spacing={3}>
                <Typography textAlign={'center'} variant={'h3'}>
                    {t('stepOne.close')}
                </Typography>
            </Stack>
        </Box>
    )
}